// extracted by mini-css-extract-plugin
export var body = "image-gallery-module--body--xZCIi";
export var container = "image-gallery-module--container--hovSS";
export var fadeIn = "image-gallery-module--fade-in--OVcJS";
export var gallery = "image-gallery-module--gallery--ab99i";
export var galleryImage = "image-gallery-module--galleryImage--NWV4f";
export var galleryLinks = "image-gallery-module--galleryLinks--LiKz-";
export var galleryNavigation = "image-gallery-module--galleryNavigation--UvsUn";
export var imageContainer = "image-gallery-module--imageContainer--rxRNH";
export var imageDescription = "image-gallery-module--imageDescription--byOPm";
export var imageTitle = "image-gallery-module--imageTitle--hvb87";
export var next = "image-gallery-module--next--4K4vq";
export var overlay = "image-gallery-module--overlay--kwvpC";
export var previous = "image-gallery-module--previous--1Yg15";
export var selectedImage = "image-gallery-module--selectedImage--vcAd0";
export var selectedImageContainer = "image-gallery-module--selectedImageContainer--8iOOG";