// Libraries
import React from 'react';
import { Link, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import get from 'lodash/get';
// Components
import Seo from '../components/seo';
import Layout from '../components/layout';
import Hero from '../components/hero';
import Troll from '../components/troll';
// Styles
import * as styles from './image-gallery.module.css';
// Utils
import { GALLERY_SHOW } from '../utils/routeConstants';
import ImagePreview from '../components/image-preview';

class ImageGalleryTemplate extends React.Component {
  state = {
    gallery: undefined,
    previous: undefined,
    next: undefined,
    selectedImage: undefined,
    trollText: undefined,
  };

  componentDidMount() {
    const gallery = get(this.props, 'data.contentfulGallery');
    const previous = get(this.props, 'data.previous');
    const next = get(this.props, 'data.next');
    const trollText = gallery?.trollText;

    this.setState({
      ...this.state,
      gallery,
      previous,
      next,
      trollText,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.selectedImage > -1) {
      document.querySelectorAll('[data-nohover="0"]').forEach((li) => {
        li.dataset['nohover'] = '1';
      });
    } else {
      document.querySelectorAll('[data-nohover="1"]').forEach((li) => {
        li.dataset['nohover'] = '0';
      });
    }
  }

  decrement() {
    this.setState({
      ...this.state,
      selectedImage:
        this.state.selectedImage > 0
          ? this.state.selectedImage - 1
          : this.state.gallery.images.length - 1,
    });
  }

  increment() {
    this.setState({
      ...this.state,
      selectedImage:
        this.state.selectedImage === this.state.gallery.images.length - 1
          ? 0
          : this.state.selectedImage + 1,
    });
  }

  render() {
    const { gallery, previous, next, trollText } = this.state;

    const renderImages = () => {
      return gallery?.images?.map((image, index) => {
        return (
          <li
            key={`${index + 1}-${image.title}`}
            data-nohover="0"
            className={styles.galleryImage}
            onClick={() =>
              this.setState({
                ...this.state,
                selectedImage: index,
              })
            }
          >
            <GatsbyImage alt={image.title} image={image.gatsbyImageData} />
          </li>
        );
      });
    };

    const selectedImage = gallery?.images[this.state.selectedImage];

    return (
      <Layout location={this.props.location}>
        <Seo
          title={gallery?.title}
          description={gallery?.description}
          image={`http:${gallery?.heroImage?.resize.src}`}
        />
        <Hero
          image={gallery?.heroImage?.gatsbyImageData}
          title={gallery?.title}
          content={'   '}
        />
        <div className={styles.container}>
          {trollText && (
            <Troll trollWidth={'60%'} top={'11em'} left={'-12em'}>
              <p style={{ padding: 10 }}>{trollText.trollText}</p>
            </Troll>
          )}
          {selectedImage && (
            <ImagePreview
              image={selectedImage}
              increment={this.increment.bind(this)}
              decrement={this.decrement.bind(this)}
              closeOverlay={() =>
                this.setState({ ...this.state, selectedImage: undefined })
              }
            />
          )}
          <ul className={styles.gallery}>{renderImages()}</ul>
          <div className={styles.galleryLinks}>
            {(previous || next) && (
              <nav>
                <ul className={styles.galleryNavigation}>
                  {previous && (
                    <li>
                      <Link to={GALLERY_SHOW(previous.slug)} rel="prev">
                        ← {previous.title}
                      </Link>
                    </li>
                  )}
                  {next && (
                    <li>
                      <Link to={GALLERY_SHOW(next.slug)} rel="next">
                        {next.title} →
                      </Link>
                    </li>
                  )}
                </ul>
              </nav>
            )}
          </div>
        </div>
      </Layout>
    );
  }
}

export default ImageGalleryTemplate;

export const galleryQuery = graphql`
  query GalleryBySlug(
    $slug: String!
    $previousGallerySlug: String
    $nextGallerySlug: String
  ) {
    contentfulGallery(slug: { eq: $slug }) {
      slug
      title
      trollText {
        childMarkdownRemark {
          html
        }
        trollText
      }
      heroImage {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 1280)
        resize(height: 630, width: 1200) {
          src
        }
      }
      images {
        title
        description
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 1280)
        resize(height: 630, width: 1200) {
          src
        }
      }
    }
    previous: contentfulGallery(slug: { eq: $previousGallerySlug }) {
      slug
      title
    }
    next: contentfulGallery(slug: { eq: $nextGallerySlug }) {
      slug
      title
    }
  }
`;
