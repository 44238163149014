// Libraries
import React, { useRef, useEffect, useCallback } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
// Assets
import * as styles from '../templates/image-gallery.module.css';

const LEFT_KEY_CODE = 37;
const RIGHT_KEY_CODE = 39;
const ESCAPE_KEY_CODE = 27;

const ImagePreview = ({ image, closeOverlay, decrement, increment }) => {
  const myDiv = useRef(null);

  const handleKey = useCallback(
    (e) => {
      switch (e.keyCode) {
        case LEFT_KEY_CODE:
          decrement();
          break;
        case RIGHT_KEY_CODE:
          increment();
          break;
        case ESCAPE_KEY_CODE:
          closeOverlay();
          break;
        default:
          break;
      }
    },
    [decrement, increment, closeOverlay]
  );

  useEffect(() => {
    const copy = myDiv;

    myDiv?.current?.addEventListener('keydown', handleKey);
    myDiv?.current?.focus();

    return () => copy?.current?.removeEventListener('keydown', handleKey);
  }, [handleKey]);

  const handleOutsideClick = (event) => {
    event.preventDefault();
    if (event.target === event.currentTarget) {
      closeOverlay();
    }
  };

  return (
    <div className={styles.overlay} onClick={handleOutsideClick}>
      <div className={styles.selectedImageContainer} tabIndex="0" ref={myDiv}>
        <div
          role="button"
          tabIndex="-1"
          className={styles.previous}
          onClick={decrement}
        >
          {'<'}
        </div>
        <div className={styles.imageContainer}>
          <h1 className={styles.imageTitle}>{image.title}</h1>
          <GatsbyImage
            onClick={closeOverlay}
            className={styles.selectedImage}
            alt={image.title}
            image={image.gatsbyImageData}
          />
          <p className={styles.imageDescription}>{image.description}</p>
        </div>
        <div
          role="button"
          tabIndex="-2"
          className={styles.next}
          onClick={increment}
        >
          {'>'}
        </div>
      </div>
    </div>
  );
};

export default ImagePreview;
